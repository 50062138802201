import { ColorThemes } from "../../../../pages/components/Casticulate/themes/colorThemes";

export function getSurveyTemplate(theme: string | null) {
  const colorTheme = ColorThemes[theme as string] || ColorThemes["default"];

  const SURVEY_TEMPLATE = `
<div class="w-full flex flex-col ${colorTheme.base} h-full px-4 py-2 md:px-8 md:py-4 rounded-lg">

  <div class="flex font-titillium justify-between items-center">
      <div class="flex text-4xl font-black whitespace-nowrap w-fit mr-8" > 
        {{#isGbf}}
          <p class=" text-teal-600 mr-1">GBF</p>  
          <p class=" text-teal-800 " >Class Builder</p>
        {{/isGbf}}
      </div>

      <!-- Progress Bar -->
      <div class="hidden md:flex bg-[#B8DED9] w-fit h-fit p-2 rounded-full items-center text-[#038B8A] font-bold">
        {{#progressBar}}
          <section class="bg-white rounded-full p-1 relative w-fit h-fit space-x-1 flex items-center justify-center">
            <i class="material-icons " >
              {{#data.icon}}{{data.icon}}{{/data.icon}}{{^data.icon}}chevron_left{{/data.icon}} 
            </i> 
            <p class="text-sm w-fit line-clamp-1 font-bold text-center {{#lastVisitedTitlePage}}{{id}}{{/lastVisitedTitlePage}}" > 
              {{data.title}}  
            </p>
          </section>
          <div class="h-2 w-4 bg-white {{#lastItemInProgressBar}}{{id}}{{/lastItemInProgressBar}}  " > 
          </div>
        {{/progressBar}}
      </div>
  </div>

  <section id="starSection" class=" bg-white w-full h-1/2 flex flex-col justify-center align-center space-y-2 p-4 my-4 rounded-3xl">
    <div>
   
      <p class="text-teal-700 bg-white h-fit w-fit max-w-3/4 mx-auto text-base font-light ">{{question}}</p>
    </div>

    <div id="starRow" class="w-fit mx-auto">
    {{#choices}}
      <button id="starButton" class="disabled:opacity-75 disabled:pointer-events-none" {{disabledInput}}>
        <i id="surveyStar" value={{uuid}} role="button" class="material-symbols text-3xl text-yellow-400 text text-[40px] disabled:pointer-events-none" {{disabledInput}} >
          star
        </i>
      </button>
    {{/choices}}
    </div>
  </section>

  <section id="commentSection" class=" h-1/2 flex flex-col justify-center items-center w-full space-y-2 p-4 ${colorTheme.gradientPanel} rounded-3xl" >
    <div class="flex-1 flex flex-col justify-center items-center w-full">
      <p class="text-white font-medium" >{{instructions}}</p>
      <textarea id="commentInput" class="rounded-xl border-teal-700 text-xl text-teal-700 w-full max-h-full resize-none disabled:opacity-75" rows="4" {{disabledInput}} ></textarea>
    </div>

    <div class=" w-full  flex justify-between self-end ${colorTheme.gradientPanel} pt-2 box-border h-fit rounded-b-2xl" >
      <button id="backButton" class="text-white p-2 font-titillium font-semibold rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 " >
        <i class="material-icons  " id="previousPage" >arrow_back</i>
      </button>

      <button id="{{buttonId}}"  {{disabled}}  class="text-white p-2 font-titillium font-semibold md:px-4 md:py-2  rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 disabled:opacity-50 ml-auto hover:opacity-80   " >
        <span class="hidden md:inline-block">{{buttonText}}</span>   
        <i class="material-icons  " >arrow_forward</i>
      </button>
    </div>
  </section>

</div>`;

  if (theme === null || theme === undefined) {
    return SURVEY_TEMPLATE;
  }

  return SURVEY_TEMPLATE;
}
