import React, { useContext, useEffect, useMemo } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getNavSlideTemplate } from "./template/navSlideTemplate";

export interface CasticulateNavSlideProps {
  node?: CasticulateNode;
  titleSlides?: { id: string; data: any }[];
}

export function CasticulateNavSlide({
  node,
  titleSlides,
}: CasticulateNavSlideProps) {
  const [menuVisibility, setMenuVisibility] = useState(true);
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  function handleMenuVisibility() {
    setMenuVisibility((prev) => !prev);
  }

  // Navslide Use Effect
  useEffect(() => {
    // Get all button under an element with  id="choices"
    const burgerMenuElement = document.getElementById("burgerMenu");
    const hiddenBurgerMenuElement = document.getElementById("hiddenBurgerMenu");

    if (burgerMenuElement)
      burgerMenuElement.addEventListener("click", handleMenuVisibility);

    if (hiddenBurgerMenuElement)
      hiddenBurgerMenuElement.addEventListener("click", handleMenuVisibility);

    return () => {
      if (burgerMenuElement)
        burgerMenuElement?.removeEventListener("click", handleMenuVisibility);

      if (hiddenBurgerMenuElement)
        hiddenBurgerMenuElement?.addEventListener(
          "click",
          handleMenuVisibility
        );
    };
  }, []);

  // Nav Slide UseEffect
  useEffect(() => {
    const menuSectionElement = document.getElementById("menuSection");
    const hiddenBurgerMenu = document.getElementById("hiddenBurgerMenu");
    if (menuSectionElement && hiddenBurgerMenu) {
      if (menuVisibility) {
        menuSectionElement.classList.remove("hidden");
        menuSectionElement.classList.add("flex");

        hiddenBurgerMenu.classList.add("hidden");
        hiddenBurgerMenu.classList.remove("block");
      } else {
        menuSectionElement.classList.remove("flex");
        menuSectionElement.classList.add("hidden");

        hiddenBurgerMenu.classList.remove("hidden");
        hiddenBurgerMenu.classList.add("block");
      }
    }
  }, [menuVisibility]);

  useEffect(() => {
    const navButtons = document.querySelectorAll("#titleNavigationButton");

    navButtons.forEach((navButton) => {
      const navButtonUuid = navButton.getAttribute("value") || "";
      navButton.addEventListener(
        "click",
        slideContext.handleSlideJumping.bind(slideContext, navButtonUuid)
      );

      if (navButtonUuid === node?.id) {
        navButton.classList.add("font-bold");
      }
    });

    slideContext.handleRerun();
  }, [menuVisibility]);

  const NAVSLIDE_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      text: nodeData.title,
      tileSlides: titleSlides,
      imageUri: nodeData.imageUri,
      buttonId: node?.isEndNode ? "finishButton" : "nextButton",
      buttonText: node?.isEndNode ? "Finish" : "Continue",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [
      nodeData,
      slideContext.progressBar,
      titleSlides,
      slideContext.lastVisitedTitlePageId,
    ]
  );

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getNavSlideTemplate(nodeData.theme)}
        view={NAVSLIDE_TEMPLATE_VIEW}
      />
    </div>
  );
}

// NAVSLIDE TEMPLATE -----------------------------------------------------------------------------------------------------------------------------------------------------
