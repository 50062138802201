import { gql, useQuery } from "@apollo/client";
import { createRef, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Button } from "../../../atoms/components";
import { NavbarQuery } from "../../../generated/graphql";
import {
  ClaimAccountReminder,
  IconButton,
  ProfileMenu,
} from "../../../molecules/components";
import { useErrorHandler } from "react-error-boundary";
import { useHotkeys } from "react-hotkeys-hook";
import { Dropdown } from "../Dropdown";
import { parseColorToStyle } from "../../../helpers/ColorHelper";
import { isGuestContext } from "../../../context/components/IsGuest";
import castLogo from "../../../assets/default-logo.png";
import { useNavbarQuery } from "../../../generated/graphql";

interface _Link {
  path: string;
  text?: string;
  icon?: string;
  style?: string;
}

export interface Profile {
  userInfo: {
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
  };
}

export interface NavbarProps {
  paths?: Array<_Link>;
}

// We can promote this into an atom if gagamitin din sa ibang component
export const ProfileIconFromText = ({
  text,
  size = 8, // Defaults to h-8 w-8. Do tailwind safelist if this component is promoted to atom
}: {
  text: string;
  size?: number;
}) => {
  const words = text.split(" ");
  const letters = words.map((word) => word.charAt(0));
  const initials = letters.join("").toUpperCase();

  return (
    <span
      className={`rounded-full  w-${size} h-${size} flex items-center justify-center font-bold text-white bg-primary-700 select-none overflow-hidden`}
    >
      {initials}
    </span>
  );
};

export const Navbar = () => {
  const { loading, data, error } = useNavbarQuery();
  // const [items, setItems] = useState<Array<_Link>>([

  // ]);

  useErrorHandler(error);

  const logo = data?.navbar?.navLogoUrl;
  const screens = data?.navbar?.navScreenItems;
  const profile: Profile = {
    userInfo: {
      email: data?.navbar?.userInfo?.email as string,
      firstName: data?.navbar?.userInfo?.firstName as string,
      lastName: data?.navbar?.userInfo?.lastName as string,
      userId: data?.navbar?.userInfo?.userId as string,
    },
  };
  const logoRef = createRef<HTMLAnchorElement>();
  const ref: any = createRef();
  const [showLink, setShowLink] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMed, setOpenMed] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [visibleItemsCount, setVisibleItemsCount] = useState(0);

  useHotkeys("esc", () => setShowProfile(false), [showProfile]);

  useEffect(() => {
    function updateVisibleItemsCount() {
      if (ref.current && logoRef.current) {
        const navWidth = ref.current.clientWidth;
        const logoWidth = logoRef.current.clientWidth;
        const searchButtonWidth =
          ref.current.querySelector(".search-button")?.clientWidth || 0;
        const certificationButtonWidth =
          process.env.REACT_APP_COMELEC === "true"
            ? ref.current.querySelector(".certification-button")?.clientWidth ||
              0
            : 0;
        let totalWidth = searchButtonWidth + certificationButtonWidth;
        let count = 0;

        for (const item of ref.current.children) {
          const itemStyle = window.getComputedStyle(item);
          const itemWidth =
            item.clientWidth +
            parseFloat(itemStyle.marginLeft) +
            parseFloat(itemStyle.marginRight);
          totalWidth += itemWidth;
          if (totalWidth + logoWidth > navWidth) break;
          count++;
        }

        setVisibleItemsCount(count);
      }
    }

    window.addEventListener("resize", updateVisibleItemsCount);
    updateVisibleItemsCount();

    return () => window.removeEventListener("resize", updateVisibleItemsCount);
  }, [ref, logoRef]);

  // Extract appearance
  const { base, item, itemActive } = data?.navbar?.navAppearance || {};

  const navStyle = parseColorToStyle(base);
  const itemStyle = parseColorToStyle(item);
  const activeItemStyle = parseColorToStyle(itemActive);

  const navigationItems = [
    ...(screens || []).map((path) => {
      const isActive = window.location.pathname.includes(
        `/screen/${path?.slug}`
      );

      return {
        slug: `/screen/${path?.slug}`,
        isActive,
        screenId: path?.screenId,
        icon: path?.icon?.material || "",
        title: path?.title,
        isHidden: path?.isHidden,
      };
    }),
  ];

  const searchNavItem = {
    slug: `/screen/${data?.navbar?.searchSlug || "search"}`,
    isActive: window.location.pathname.includes(
      `/screen/${data?.navbar?.searchSlug || "search"}`
    ),
    screenId: 3000,
    icon: "search",
  };

  if (
    /buribooks/g.test(window.location.href) ||
    /wikaygaling/g.test(window.location.href)
  ) {
    navigationItems.push({
      slug: "/diksiyonaryo",
      isActive: window.location.pathname.includes(`/diksiyonaryo`),
      screenId: null,
      icon: "list_alt",
      title: "Diksiyonaryo",
      isHidden: false,
    });
  }

  const { isGuest } = useContext(isGuestContext);

  return (
    <div>
      <nav
        className="flex items-center justify-between px-4 py-2 space-x-2 shadow-md h-14 "
        style={navStyle}
      >
        {/*logo here*/}
        <Link to="/" ref={logoRef}>
          <Image
            src={logo || castLogo}
            alt="logo"
            className="h-10 bg-transparent"
          ></Image>
        </Link>

        <div className="flex flex-1 h-14 ">
          {/* Large Nav */}
          <ul
            ref={ref}
            className={`justify-end flex items-center w-full h-14 space-x-2`}
          >
            {navigationItems
              .slice(0, visibleItemsCount)
              .map((item, index: number) => {
                const activeScreenName = window.location.pathname;
                const isActive = activeScreenName === item.slug;

                return (
                  <li key={"navigation item: " + index}>
                    <Link
                      style={isActive ? activeItemStyle : itemStyle}
                      onMouseOver={(e) => {
                        if (!isActive) {
                          e.currentTarget.style.color =
                            activeItemStyle.color || "";
                          e.currentTarget.style.backgroundColor =
                            activeItemStyle.backgroundColor || "";
                        }
                      }}
                      onMouseOut={(e) => {
                        if (!isActive) {
                          e.currentTarget.style.color = itemStyle.color || "";
                          e.currentTarget.style.backgroundColor =
                            itemStyle.backgroundColor || "";
                        }
                      }}
                      to={item.slug}
                      className={` my-2 px-2 py-1 flex flex-none items-center rounded ${
                        isActive
                          ? "text-white bg-primary-600"
                          : "text-primary-500 hover:bg-primary-200"
                      } ${item.isHidden ? "hidden" : ""}`}
                    >
                      <Icon name={item.icon} className="mr-1"></Icon>
                      <p className="overflow-hidden overflow-ellipsis max-w-24 lg:max-w-36 line-clamp-1">
                        {item.title}
                      </p>
                    </Link>
                  </li>
                );
              })}
            {process.env.REACT_APP_COMELEC === "true" && (
              <li
                key={"Certificates tab"}
                style={
                  window.location.pathname.includes("/profile/certification")
                    ? activeItemStyle
                    : itemStyle
                }
                className={` certification-button my-2 px-2 py-1 flex flex-none items-center rounded ${
                  window.location.pathname.includes("/profile/certification")
                    ? "text-white bg-primary-600"
                    : "text-primary-500 hover:bg-primary-200"
                }`}
              >
                <Link to="/profile/certification">
                  Course and Certification
                </Link>
              </li>
            )}
            <li key={"search nav item"} className="search-button">
              <Link
                to={searchNavItem.slug}
                style={searchNavItem.isActive ? activeItemStyle : itemStyle}
                onMouseOver={(e) => {
                  if (!searchNavItem.isActive) {
                    e.currentTarget.style.color = activeItemStyle.color || "";
                    e.currentTarget.style.backgroundColor =
                      activeItemStyle.backgroundColor || "";
                  }
                }}
                onMouseOut={(e) => {
                  if (!searchNavItem.isActive) {
                    e.currentTarget.style.color = itemStyle.color || "";
                    e.currentTarget.style.backgroundColor =
                      itemStyle.backgroundColor || "";
                  }
                }}
                className={` my-2 px-2 py-1 flex flex-none items-center rounded ${
                  searchNavItem.isActive
                    ? "text-white bg-primary-600"
                    : "text-primary-500 hover:bg-primary-200"
                }`}
              >
                <Icon name={searchNavItem.icon}></Icon>
              </Link>
            </li>

            {navigationItems.length > visibleItemsCount && (
              <li className="menu-button" key={"menu item"}>
                <IconButton
                  type="button"
                  icon="menu"
                  color="primaryInverted"
                  style={searchNavItem.isActive ? activeItemStyle : itemStyle}
                  className={` my-2 px-2 py-1 flex flex-none items-center rounded  ${
                    searchNavItem.isActive
                      ? "text-white bg-primary-600"
                      : "text-primary-500 hover:bg-primary-200"
                  }`}
                  onClick={() => setOpen(!open)}
                ></IconButton>
              </li>
            )}
          </ul>

          {/*Pag hindi na kasya mga links sa navbar*/}
          <Dropdown
            open={openMed}
            paths={navigationItems.slice(visibleItemsCount)}
          />
          {/* Mobile Nav */}
          <ul
            className={`${
              navigationItems.length > visibleItemsCount ? "" : "md:hidden"
            } absolute h-full overflow-y-auto items-center top-0 py-10 px-4 z-[100] bg-white w-1/2 md:w-1/3 shadow-lg duration-500 ${
              open ? `left-0` : "left-[-200%]"
            }`}
          >
            {navigationItems.map((item, index) => (
              <li key={"Scrren id number: " + index}>
                <Link
                  to={item.slug}
                  className="flex items-center w-full px-2 py-2 mb-2 text-lg text-left rounded text-primary-500 hover:bg-primary-200"
                >
                  <Icon name={item.icon} className="mr-1"></Icon>
                  <p className="overflow-hidden line-clamp-2 overflow-ellipsis">
                    {item.title}
                  </p>
                </Link>
              </li>
            ))}

            <li key={"search nav item"}>
              <Link
                to={searchNavItem.slug}
                className="flex items-center w-full px-2 py-2 mb-2 text-lg text-left rounded text-primary-500 hover:bg-primary-200"
              >
                <Icon name={searchNavItem.icon} className="mr-1"></Icon>
              </Link>
            </li>

            {process.env.REACT_APP_COMELEC === "true" || (
              <li key={"Certificates tab"}>
                <Link to="/profile/certification">
                  Course and Certification
                </Link>
              </li>
            )}
          </ul>

          {/*Profile pic*/}
        </div>
        <button
          onClick={() => {
            setShowProfile(!showProfile);
          }}
        >
          <ProfileIconFromText
            text={
              (profile.userInfo.firstName !==
              "DEPRECATED - use traits.given_name"
                ? profile.userInfo.firstName?.[0]
                : "Guest") +
              " " +
              (profile.userInfo.lastName !==
              "DEPRECATED - use traits.family_name"
                ? profile.userInfo.lastName?.[0]
                : "Guest")
            }
          ></ProfileIconFromText>
        </button>
        {showProfile ? (
          <ProfileMenu setShowProfile={setShowProfile} profile={profile} />
        ) : (
          ""
        )}
      </nav>

      {isGuest ? <ClaimAccountReminder /> : ""}
    </div>
  );
};

// A react component function that convert txt into a pseudo circular image consisting of the first letter of each word
