import React, { useContext, useEffect, useLayoutEffect, useMemo } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getSurveyTemplate } from "./template/surveyTemplate";

export interface CasticulateSurveyProps {
  node?: CasticulateNode;
}

export function CasticulateSurvey({ node }: CasticulateSurveyProps) {
  const slideContext = useContext(SlideContext);
  const previousRecord = slideContext.slideStates.find(
    (slideState) => slideState.slideUuid === node?.id
  )?.assessmentSlideState;

  const [selectedStarValue, setSelectedStarValue] = useState<number | null>(
    Number(previousRecord?.answers?.[0]) || null
  );
  const [commentString, setCommentString] = useState<string>(
    previousRecord?.answers?.[1] || ""
  );
  const [hasAnswered, setHasAnswered] = useState<boolean>(
    previousRecord?.hasAnswered || false
  );

  const changedAnswer =
    previousRecord?.answers?.[0] !== selectedStarValue?.toString() ||
    previousRecord?.answers?.[1] !== commentString;

  const nodeData = camelcaseKeys(node?.data, { deep: true });
  const SURVEY_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      title: nodeData?.title || "Survey",
      question: nodeData?.question || "Rate this slide so far",
      disabled:
        commentString === "" || selectedStarValue === null ? "disabled" : "",
      disabledInput: previousRecord ? "disabled" : "",
      choices: [
        {
          value: 1,
          uuid: 1,
        },
        {
          value: 2,
          uuid: 2,
        },
        {
          value: 3,
          uuid: 3,
        },
        {
          value: 4,
          uuid: 4,
        },
        {
          value: 5,
          uuid: 5,
        },
      ],

      buttonId:
        hasAnswered && !changedAnswer
          ? node?.isEndNode
            ? "finishButton"
            : "nextButton"
          : "submitButton",
      buttonText:
        hasAnswered && !changedAnswer
          ? node?.isEndNode
            ? "Finish"
            : "Continue"
          : "Answer to Continue",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
      instructions: nodeData?.instructions || "Please leave your comments:",
    }),
    [
      node,
      hasAnswered,
      commentString,
      previousRecord,
      selectedStarValue,
      slideContext.lastVisitedTitlePageId,
    ]
  );

  // Survey Use Effect For Stars
  useEffect(() => {
    const stars = document.querySelectorAll("#starRow #surveyStar");
    const starButtons = document.querySelectorAll("#starButton");
    starButtons.forEach((starButton, index) => {
      starButton.addEventListener("click", () => {
        setSelectedStarValue((prev) => index);
      });

      if (selectedStarValue === null || index > selectedStarValue) {
        stars[index].innerHTML = "star_outline";
      } else {
        stars[index].innerHTML = "star";
      }
    });

    return () => {
      stars.forEach((star, index) => {
        star.removeEventListener("click", () => {
          setSelectedStarValue(index);
        });
      });
    };
  }, [
    selectedStarValue,
    commentString,
    slideContext.slideStates,
    SURVEY_TEMPLATE_VIEW,
  ]);

  const submitFunction = () => {
    setHasAnswered(true);
    slideContext.appendSlideStates({
      slideUuid: node?.id || "",
      assessmentSlideState: {
        activityType: node?.slideType || "",
        answers: [selectedStarValue?.toString() || "0", commentString],
        answersInText: [
          (selectedStarValue ? selectedStarValue + 1 : 0)?.toString(),
          commentString,
        ],
        isCorrect: null,
        hasAnswered: true,
      },
    });
  };

  function commentInputHandler(this: HTMLInputElement) {
    setCommentString(this.value);
  }

  useLayoutEffect(() => {
    const commentInputElement: HTMLTextAreaElement = document.getElementById(
      "commentInput"
    ) as HTMLTextAreaElement;
    if (commentInputElement !== null) {
      commentInputElement.innerText = commentString;
      commentInputElement.selectionStart = commentInputElement.selectionEnd =
        commentInputElement.value.length;
      commentInputElement.focus();
    }
  }, [commentString, SURVEY_TEMPLATE_VIEW]);

  useEffect(() => {
    const commentInputElement = document.getElementById("commentInput");
    commentInputElement?.addEventListener("input", commentInputHandler);
    return () => {
      if (commentInputElement)
        commentInputElement.removeEventListener("input", commentInputHandler);
    };
  }, [commentString, node, SURVEY_TEMPLATE_VIEW]);

  useLayoutEffect(() => {
    const commentInputElement = document.getElementById("commentInput");
    if (commentInputElement) commentInputElement.innerText = commentString;
  }, [previousRecord]);

  // Use effect for submission
  useLayoutEffect(() => {
    const submitButton = document.getElementById("submitButton");
    submitButton?.addEventListener("click", submitFunction);

    slideContext.handleRerun();
    return () => {
      submitButton?.removeEventListener("click", submitFunction);
    };
  }, [SURVEY_TEMPLATE_VIEW, commentString]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getSurveyTemplate(nodeData.theme)}
        view={SURVEY_TEMPLATE_VIEW}
      />
    </div>
  );
}

// SURVEY TEMPLATE ----------------------------------------------------------------------------------------------------------------------------------------------------------
