import { Icon } from "../../../atoms/components";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { Image, Button } from "../../../atoms/components";
import { randomBuriDog } from "../../../helpers/components/RandomBuriDog";
import { getCastlmsDomain, getGuestLoginUrl } from "../Login";
import { LoadingCentered } from "../../../molecules/components";
import { GetLoginScreenDocument } from "../../../generated/graphql";

export interface ErrorPageProps {}

type CustomError = Error & { status?: number };

export function ErrorPage({
  error,
  resetErrorBoundary,
}: {
  error?: CustomError | ApolloError;
  resetErrorBoundary?: any;
} = {}) {
  let navigate = useNavigate();

  const domain = getCastlmsDomain(window.location.host);
  const [isError401, setIsError401] = useState(true);

  const [
    checkIsGuest,
    { loading: loginLoading, data: loginData, error: loginError },
  ] = useLazyQuery(GetLoginScreenDocument, {
    variables: {
      domain,
    },
    onCompleted: (data) => {
      const { client_id: clientId, hero_url: heroUrl } =
        data?.login_screen || {};
      if (data?.login_screen?.cast.attributes.default_login_as_guest) {
        window.location.href = getGuestLoginUrl({
          clientId: clientId || "",
        }).href;
        navigate({
          pathname: getGuestLoginUrl({
            clientId: clientId || "",
          }).href,
        });
      } else {
        let redirect = !window.location.pathname.includes("login")
          ? `?redirect=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          : "?redirect=/";

        navigate({
          pathname: "/login",
          search: redirect,
        });

        resetErrorBoundary();
      }
    },
  });

  useEffect(() => {
    let errorStatusCodes = [];
    if (error instanceof ApolloError) {
      errorStatusCodes = error.graphQLErrors.map(
        (e: any) =>
          e.extensions?.response?.statusCode ||
          e.extensions?.response?.status ||
          e.extensions?.originalError?.statusCode
      );
    }

    if (
      !window.location.pathname.includes("login") &&
      // (errorStatusCodes.includes(401) || errorStatusCodes.includes(403))
      errorStatusCodes.includes(401)
    ) {
      console.log("includes 401", errorStatusCodes.includes(401));
      checkIsGuest();
    } else {
      setIsError401(false);
    }

    const handleBackButton = (event: any) => {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "TEXTAREA"
      ) {
        window.location.reload(); // Reload the page
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isError401]);

  if (isError401) {
    return <></>;
  }

  return (
    <div className="z-30 flex items-center h-screen">
      <div className="flex flex-col items-center mx-auto">
        <Image src={randomBuriDog()} className="w-2/3 max-h-[500px] " />
        {/* Instead of using &#  */}
        <h1 className="text-3xl font-bold text-primary-500">Sorry :&#40;</h1>
        <h1 className="mb-4 text-3xl font-bold text-primary-500">
          Something went wrong
        </h1>
        <p className="w-2/3 mx-auto mb-6 text-center">
          You may refresh this page or click the button below to go back to home
          page.
        </p>
        <Button
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          Back to Home Page
        </Button>
      </div>
    </div>
  );
}
