import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { Navigate, useSearchParams } from "react-router-dom";

import {
  useLoginMutation,
  useGetLoginScreenQuery,
  useRecipeCookMutation,
} from "../../../generated/graphql";
import { LoadingCentered } from "../../../molecules/components";
import { getCastlmsDomain } from "../Login";

export interface AuthCallbackProps {}

const LoginMutation = gql`
  mutation Login($redirectUri: String!, $code: String!, $clientId: String!) {
    login(redirect_uri: $redirectUri, code: $code, client_id: $clientId)
  }
`;

export function AuthCallback(props: AuthCallbackProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const redirect = localStorage.getItem("redirect") || "/";

  const domain = getCastlmsDomain(window.location.host);
  // const domain = "bokchoy.cast-learner-react.buri.dev";

  const {
    loading: queryLoading,
    data: queryData,
    error: queryError,
  } = useGetLoginScreenQuery({
    variables: {
      domain,
    },
    fetchPolicy: "no-cache",
  });

  let clientId = queryData?.loginScreen?.clientId || "";
  const [recipeCookMutateFunction] = useRecipeCookMutation();
  const [mutateFunction, { data, loading, error }] = useLoginMutation({
    variables: {
      clientId,
      redirectUri: `${window.location.origin}/auth/new`, // For callback backwards compatibility
      code: code as string,
    },
    onCompleted: () => {
      recipeCookMutateFunction({
        variables: {
          ingredient: {
            recipe_name: "app_launch",
            body: {
              timestamp: new Date().toISOString(),
              object: {},
              verb: "login",
              context: {},
            },
          },
        },
        onError: (error) => {
          console.log(error.name);
        },
      });
    },
  });

  useEffect(() => {
    if (clientId) {
      mutateFunction();
    }
  }, [clientId]);

  useErrorHandler(queryError || error);

  if (queryLoading || loading || queryError || error || !data || !queryData) {
    return <LoadingCentered></LoadingCentered>;
  } else {
    return <Navigate to={redirect} />;
  }
}
