import { useEffect, useState, useMemo, useContext } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import camelcaseKeys from "camelcase-keys";
import { getScrollCarouselTemplate } from "./template/scrollCarouselTemplate";
import { getCarouselTemplate } from "./template/carouselTemplate";

export interface CasticulateImageCarouselProps {
  node?: CasticulateNode;
}

export function CasticulateImageCarousel({
  node,
}: CasticulateImageCarouselProps) {
  const slideContext = useContext(SlideContext);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState<number>(
    slideContext.slideStates.find(
      (slideState) => slideState.slideUuid === (node?.id || "")
    )?.carouselSlideState?.index || 0
  );
  const [isLastImageVisible, setIsLastImageVisible] = useState<boolean>(false);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const parser = new DOMParser();
  let observer = new IntersectionObserver(
    (entries) => {
      if (nodeData.isScroll) {
        const child = `
      <button id=${
        node?.isEndNode ? "finishButton" : "nextButton"
      }  class="text-white p-2 font-titillium font-semibold  md:px-4 md:py-2  rounded-3xl border-2 border-white w-fit self-end active:opacity-70 hover:shadow-lg transition-all duration-150 ml-auto hover:opacity-80 "  >
        <span class="hidden md:inline-block">
      ${node?.isEndNode ? "Finish" : "Continue"}
        </span>
        <i class="material-icons  " id="previousPage" >
          arrow_forward
        </i>
      </button>`;
        const doc = parser.parseFromString(child, "text/html");
        const nodeChild = doc.body.firstChild;
        const navbuttons = document.getElementById("navButtons");

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (navbuttons && nodeChild) {
              navbuttons.appendChild(nodeChild as Node);
            }

            slideContext.handleRerun();
          } else {
            if (navbuttons && nodeChild) {
              const nextButton = document.getElementById("nextButton");

              if (nextButton) {
                navbuttons.removeChild(nextButton);
              }
            }
          }
        });
      }
    },
    { threshold: 0.5 }
  );

  const IMAGE_CAROUSEL_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      uris: nodeData.uris.map((uri: string, index: number) => {
        return { uri: uri, isLastSlide: index + 1 === nodeData.uris.length };
      }),
      uriToDisplay: nodeData.uris?.[currentCarouselIndex],
      backButtonId:
        currentCarouselIndex === 0 ? "backButton" : "backCarouselSlide",
      buttonId:
        currentCarouselIndex + 1 === nodeData.uris.length
          ? node?.isEndNode
            ? "finishButton"
            : "nextButton"
          : "nextCarouselSlide",
      buttonText:
        currentCarouselIndex + 1 === nodeData.uris.length
          ? node?.isEndNode
            ? "Finish"
            : "Continue"
          : "Next Slide",
      title: nodeData.title,
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },

      hideButton: isLastImageVisible ? "" : "hidden",
    }),

    [
      node,
      currentCarouselIndex,
      slideContext.lastVisitedTitlePageId,
      slideContext.progressBar,
      currentCarouselIndex,
      isLastImageVisible,
    ]
  );

  const handleNextCarouselIndex = () => {
    setCurrentCarouselIndex((prev) => prev + 1);
  };

  const handleBackCarouselIndex = () => {
    setCurrentCarouselIndex((prev) => prev - 1);
  };

  useEffect(() => {
    const sentry = document.getElementById("sentry");
    if (sentry) {
      observer.observe(sentry);
    }
  }, [IMAGE_CAROUSEL_TEMPLATE_VIEW]);

  const [reAttach, setReAttach] = useState(false);

  useEffect(() => {
    setReAttach((prev) => !prev);
  }, [currentCarouselIndex]);

  useEffect(() => {
    const nextCarouselSlide = document.getElementById("nextCarouselSlide");
    const backCarouselSlide = document.getElementById("backCarouselSlide");

    if (nextCarouselSlide) {
      nextCarouselSlide.addEventListener("click", handleNextCarouselIndex);
    }
    if (backCarouselSlide) {
      backCarouselSlide.addEventListener("click", handleBackCarouselIndex);
    }

    // Update slide states when dependencies change
    slideContext.appendSlideStates({
      slideUuid: node?.id || "",
      carouselSlideState: {
        index: currentCarouselIndex,
      },
    });

    // Remove event listeners when component unmounts or dependencies change
    return () => {
      if (nextCarouselSlide) {
        nextCarouselSlide.removeEventListener("click", handleNextCarouselIndex);
      }
      if (backCarouselSlide) {
        backCarouselSlide.removeEventListener("click", handleBackCarouselIndex);
      }
    };
  }, [currentCarouselIndex, node, reAttach]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={
          nodeData.isScroll
            ? getScrollCarouselTemplate(nodeData.theme)
            : getCarouselTemplate(nodeData.theme)
        }
        view={IMAGE_CAROUSEL_TEMPLATE_VIEW}
      />
    </div>
  );
}
