import {
  useLayoutEffect,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { SlideState } from "../../../pages/components/Casticulate";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getPpstTemplate } from "./template/ppstTemplate";

export interface CasticulatePPSTProps {
  node?: CasticulateNode;
}

export function CasticulatePPST({ node = {} }: CasticulatePPSTProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  const PPST_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      buttons: nodeData.buttons,
      uri: nodeData.imageUri,
      title: nodeData.title,
      buttonId: node.isEndNode ? "finishButton" : "nextButton",
      buttonText: node.isEndNode ? "Finish" : "Continue",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [slideContext.lastVisitedTitlePageId, slideContext.progressBar, node]
  );

  useEffect(() => {
    registerSlide();
  }, []);

  const registerSlide = () => {
    slideContext.appendSlideStates({
      slideUuid: node.id || "",
    });
  };

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getPpstTemplate(nodeData.theme)}
        view={PPST_TEMPLATE_VIEW}
      />
    </div>
  );
}
